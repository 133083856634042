import React, { Component } from "react";
import "./App.css";

import Layout3 from "../src/pages/Layout3/Layout3";

//import style
import "./assets/css/pe-icon-7.css";
import "./assets/css/materialdesignicons.min.css";

import "./assets/scss/themes.scss";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
          
          <Layout3/>
          
      </React.Fragment>
    );
  }
}

export default App;
