import React, { Component, Suspense } from "react";
import Turbine from "../../assets/images/Turbine.png";
import Wave from "../../assets/images/wave.webp";

const Section = React.lazy(() => import("./Section"));
const Services = React.lazy(() => import("../../component/Services"));
const Feature = React.lazy(() => import("../../component/Feature"));
const Footer = React.lazy(() => import("../../component/Footer/Footer"));

class Layout3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
    };
  }

  componentDidMount() {
    this.checkWindowWidth();
    window.addEventListener("resize", this.checkWindowWidth);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkWindowWidth);
  }

  checkWindowWidth = () => {
    const isMobile = window.innerWidth <= 768; // 768px altı mobil olarak kabul edilecek
    if (isMobile !== this.state.isMobile) {
      this.setState({ isMobile });
    }
  };

  render() {
    const { isMobile } = this.state;

    return (
      <React.Fragment>
        <Suspense fallback={this.Loader()}>

          <div
            style={{
              backgroundImage: !isMobile ? `url(${Turbine})` : 'none',
              backgroundSize: "100vw",
              backgroundRepeat: "no-repeat",
              top: 0,
              left: 0,
              position: 'relative', // Eğer arka plan yüklenirse scroll yapılabilir olmasını sağlar
            }}
          >
            {/* Importing Section */}
            <Section />

            {/* Importing Services */}
            <Services />

            {/* Wave Background */}
              <div
                style={{
                  display: 'flex',
                  height: '100vh', // Tam viewport yüksekliği
                  backgroundImage: `url(${Wave})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: "100vw",
                  position: 'absolute',
                  transform: 'translateY(-52vh)', // Yatayda tam ortaya gelecek
                  width: '100%',
                  zIndex: -1000,
                }}
              />

            {/* Importing Feature */}
            <Feature />

            {/* Importing Footer */}
            <Footer />

          </div>

        </Suspense>
      </React.Fragment>
    );
  }

  // Loader
  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
      </div>
    );
  };
}

export default Layout3;
